h1 {
    font-family: Cern,Helvetica,Arial,sans-serif;
    color: #111111;
    font-weight: 300;
    letter-spacing: -0.3px;
    font-size: 1.4rem;
}

h2 {
  font-family: Cern,Helvetica,Arial,sans-serif;
  color: #111111;
  font-weight: 500;
  letter-spacing: -0.3px;
  font-size: 1.0rem;
}

p {
  font-size: 0.9rem;
  line-height: 1.5;
  color: #383838;
  font-weight: 300;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loader {
    border-top-color: #3498db;
    animation: spin 1s linear infinite;
  }
  