@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.table-container {
  max-width: 950px;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.error-message {
  color: red;
  display: block;
}

.error-input {
  border: 1px solid red;;
}